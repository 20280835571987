export const config = {
    name: 'companyManagerArchive',
    searchFields: [
        {
            name: '分公司',
            value: 'companyName',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'companyNameCn', 'value': '分公司名称' },
                { 'prop': 'companyCode', 'value': '分公司编码' }
            ],
            tableConfig: [
                { 'prop': 'companyCode', 'value': '分公司编码' },
                { 'prop': 'companyNameCn', 'value': '分公司名称', 'displayName': 'companyNameCn' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/esCompany/popCompany`,
            cbParams: ['companyCode', 'companyNameCn#companyName']
        },
        {
            name: '所属区域',
            value: 'region',
            type: 'input',
            isFixed: true,
            span: 3
        },
        {
            name: '营销经理',
            value: 'marketingManager',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名', 'displayName': 'esusUserNameCn' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#marketingManager', 'esusLoginName#marketingManagerCode'],
            beforeRequest: (params) => {
                const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.esusUserNameCn = inputKey;
                    params.esusLoginName = null;
                } else {
                    params.esusLoginName = inputKey;
                    params.esusUserNameCn = null;
                }
                return params;
            }
        },
        {
            name: '续签对接人',
            value: 'renewalManager',
            type: 'advance',
            isFixed: true,
            span: 3,
            advanceConfig: [
                { 'prop': 'esusUserNameCn', 'value': '姓名' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusId', 'value': 'ID' }
            ],
            tableConfig: [
                { 'prop': 'esusId', 'value': 'ID' },
                { 'prop': 'esusLoginName', 'value': '美信号' },
                { 'prop': 'esusUserNameCn', 'value': '姓名', 'displayName': 'esusUserNameCn' },
                { 'prop': 'esusPositionName', 'value': '职位' },
                { 'prop': 'escoCompanyNameCn', 'value': '部门' }
            ],
            prefix: '/api-mdm',
            advanceUrl: `/selectUserCompany`,
            cbParams: ['esusUserNameCn#renewalManager', 'esusLoginName#renewalManagerCode'],
            beforeRequest: (params) => {
                const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                if (reg.test(inputKey)) {
                    params.esusUserNameCn = inputKey;
                    params.esusLoginName = null;
                } else {
                    params.esusLoginName = inputKey;
                    params.esusUserNameCn = null;
                }
                return params;
            }
        }
    ],
    selection: false,
    index: false,
    tableData: {
        columns: [
            {
                label: '分公司编码',
                prop: 'companyCode',
                minWidth: 150
            },
            {
                label: '分公司名称',
                prop: 'companyName',
                minWidth: 150
            },
            {
                label: '所属区域',
                prop: 'region',
                minWidth: 150
            },
            {
                label: '营销经理',
                prop: 'marketingManager',
                minWidth: 150
            },
            {
                label: '营销经理mip账号',
                prop: 'marketingManagerCode',
                minWidth: 150
            },
            {
                label: '续签对接人',
                prop: 'renewalManager',
                minWidth: 150
            },
            {
                label: '续签对接人mip账号',
                prop: 'renewalManagerCode',
                minWidth: 150
            },
            {
                label: '价格维护统筹人',
                prop: 'priceCoordinatorName',
                value: 'priceCoordinatorName',
                name: '添加价格维护统筹人',
                type: 'advance',
                advanceConfig: [
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusLoginName', 'value': '美信号' }
                ],
                tableConfig: [
                    { 'prop': 'esusId', 'value': 'ID' },
                    { 'prop': 'esusLoginName', 'value': '美信号' },
                    { 'prop': 'esusUserNameCn', 'value': '姓名' },
                    { 'prop': 'esusPositionName', 'value': '职位' },
                    { 'prop': 'escoCompanyNameCn', 'value': '部门' }
                ],
                prefix: '/api-mdm',
                advanceUrl: `/selectUserCompany`,
                cbParams: ['esusUserNameCn#priceCoordinatorName', 'esusLoginName#priceCoordinatorMip'],
                beforeRequest: (params) => {
                    const inputKey = (params.esusUserNameCn ? params.esusUserNameCn : (params.esusLoginName ? params.esusLoginName : '')).trim();
                    const reg = new RegExp('[\\u4E00-\\u9FFF]+', 'g'); // 中文字符正则
                    if (reg.test(inputKey)) {
                        params.esusUserNameCn = inputKey;
                        params.esusLoginName = null;
                    } else {
                        params.esusLoginName = inputKey;
                        params.esusUserNameCn = null;
                    }
                    return params;
                },
                width: 150
            },
            {
                label: '价格维护统筹人mip账号',
                prop: 'priceCoordinatorMip',
                width: 154
            },
            {
                label: '状态',
                prop: 'state',
                type: 'select',
                disabled: true,
                optionsKey: 'SYS_CRM_CONTRACT_PRICE_STATUS',
                minWidth: 150
            },
            {
                label: '创建人mip',
                prop: 'createUserCode',
                minWidth: 150
            },
            {
                label: '创建人名称',
                prop: 'createUserName',
                minWidth: 150
            },
            {
                label: '创建时间',
                prop: 'createTime',
                minWidth: 150
            },
            {
                label: '修改人mip',
                prop: 'updateUserCode',
                minWidth: 150
            },
            {
                label: '修改人名称',
                prop: 'updateUserName',
                minWidth: 150
            },
            {
                label: '修改时间',
                prop: 'updateTime',
                minWidth: 150
            }
        ]
    },
    actions: { // 表格操作列的配置
        fixedWidth: 80, // fixedWidth（可选） 设置操作列的固定宽度，不加该参数默认是按钮list长度*50px
        list: []
    }
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"costomer-series-manage"},[_c('Paper',{staticClass:"costomer-series-manage-paper"},[_c('list-layout-paper',{scopedSlots:_vm._u([{key:"list",fn:function(ref){
var tableHeight = ref.tableHeight;
return [_c('edit-table-box',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.contractList.tableLoading),expression:"contractList.tableLoading"}],ref:"clientOrder",attrs:{"index":_vm.config.index,"control":true,"selection":_vm.config.selection,"columns":_vm.config.tableData.columns,"height":tableHeight,"tableData":_vm.contractList.totalData,"actions":_vm.config.actions,"allowRowClick":false},on:{"element-change":_vm.handleChangeRow},scopedSlots:_vm._u([{key:"col-append",fn:function(ref){
var col = ref.col;
var row = ref.row;
return [(col.prop === 'businessType')?_c('div',[_vm._v(_vm._s(col.prop === 'businessType' ? row.busiLabelList:row.businessType))]):_vm._e()]}}],null,true)})]}}])},[_c('template',{staticClass:"searchBox",slot:"header-search"},[_c('search-box',{ref:"searchBox",attrs:{"search-key":_vm.config.name,"fields":_vm.config.searchFields,"showResetBtn":true},on:{"search-change":_vm.contractList.searchList}})],1),_c('template',{slot:"header-button"},[_c('lotsButton',{directives:[{name:"has",rawName:"v-has",value:(_vm.authorityCode.export),expression:"authorityCode.export"}],attrs:{"type":"primary","size":"mini","title":"导出"}},[_c('report-export',{attrs:{"reportCode":"REPORT_CRM_COMPANY_ARCHIVE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var submit = ref.submit;
return [_c('span',{on:{"click":function($event){return _vm.handleExport(submit, 'split')}}},[_vm._v("导出")])]}}])})],1),_c('lotsButton',{attrs:{"type":"primary","size":"mini","title":"刷新","plain":""},on:{"click":_vm.refresh}},[_c('span',{staticClass:"icon iconfont icon-refresh"})]),_c('el-tooltip',{attrs:{"effect":"dark","content":'列配置',"placement":"bottom"}},[_c('el-button',{attrs:{"icon":"iconfont icon-list_install","type":"primary","size":"mini","title":'列配置',"plain":""},on:{"click":_vm.showColumnConfigDialog}})],1)],1),_c('template',{slot:"footer"},[_c('lots-pagination',{attrs:{"current-page":_vm.contractList.pageNo,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.contractList.pageTotal},on:{"size-change":_vm.contractList.sizeChange,"update:currentPage":function($event){return _vm.$set(_vm.contractList, "pageNo", $event)},"update:current-page":function($event){return _vm.$set(_vm.contractList, "pageNo", $event)},"current-change":_vm.contractList.pageChange}})],1)],2),_c('column-config',{ref:"otpColumnConfig",attrs:{"pageID":_vm.config.name,"initColumns":_vm.config.tableData.columns},on:{"header-change":function($event){_vm.config.tableData.columns = $event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import request from '@/utils/http';
const apiCrmHost = 'api-lcrm';

// 分公司关联人员档案表查询
export const companyManagerArchivePage = (data) => {
    return request({
        url: apiCrmHost + '/company/manager/archive/page',
        method: 'post',
        data
    });
};
// 分公司关联人员档案表查询
export const updateCompanyManagerArchive = (data) => {
    return request({
        url: apiCrmHost + '/company/manager/archive/update/priceCoordinator',
        method: 'post',
        data
    });
};
